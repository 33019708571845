<template>
    <el-main>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="商家名称：">
                <el-input size="small" v-model="formData.store_name" placeholder="请输入商家名称"></el-input>
            </el-form-item>
            <el-form-item label="商家电话：">
                <el-input size="small" v-model="formData.store_phone" placeholder="请输入商家电话"></el-input>
            </el-form-item>
            <el-form-item label="运营商名称：">
                <el-input size="small" v-model="formData.f_name" placeholder="请输入运营商名称"></el-input>
            </el-form-item>
            <el-form-item label="运营商电话：">
                <el-input size="small" v-model="formData.f_mobile" placeholder="请输入运营商电话"></el-input>
            </el-form-item>
            <el-form-item label="发展商名称：">
                <el-input size="small" v-model="formData.r_name" placeholder="请输入发展商名称"></el-input>
            </el-form-item>
            <el-form-item label="发展商电话：">
                <el-input size="small" v-model="formData.r_mobile" placeholder="请输入发展商电话"></el-input>
            </el-form-item>
            <el-form-item label="状态：">
                <el-select v-model="formData.status" size="small" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="运营商审核中" :value="-1"></el-option>
                    <el-option label="平台审核中" :value="1"></el-option>
                    <el-option label="已完成" :value="2"></el-option>
                    <el-option label="已驳回" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="注册日期：">
                <el-date-picker size="small" v-model="formData.time" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="staff_name" label="商家信息" align="center">
                <template v-slot="{ row }">
                    <UserInfo :name="row.store_name" :avatar="row.store_logo"></UserInfo>
                </template>
            </el-table-column>
            <el-table-column prop="store_phone" label="商家电话" align="center"></el-table-column>
            <el-table-column prop="r_name" label="所属发展商姓名" align="center"></el-table-column>
            <el-table-column prop="r_mobile" label="所属发展商电话" align="center"></el-table-column>
            <el-table-column prop="f_name" label="审核运营商姓名" align="center"></el-table-column>
            <el-table-column prop="f_mobile" label="审核运营商电话" align="center"></el-table-column>
            <el-table-column label="申请时间" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column label="审核状态" align="center">
                <template v-slot="{ row }"><span
                        :style="{ color: row.status == -1 || row.status == 1 ? '#fdd099' : row.status == 2 ? '#5a8cff' : '#ff9898' }">{{
                            row.status === -1 ? '运营商审核中' : row.status === 1 ? '平台审核中' : row.status
                                === 2 ?
                                '已完成' : row.status === 3 ? '已驳回' : '异常' }}</span></template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type='text' @click="lookDetail(row)">查看详情</el-button>
                    <el-button type='text' v-if="row.status == 1" @click="openExamineDialog(row)">审核</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="formData.page" :pageNum="formData.rows" @updatePageNum="updateData">
        </Paging>

        <!-- 查看详情弹窗 -->
        <el-dialog title="查看" :visible.sync="detail_info_flag" width="600px">
            <el-form label-width="120px">
                <el-form-item label="商家信息">
                    <UserInfo :name="detail_info_stote.store_name" :avatar="detail_info_stote.store_logo"></UserInfo>
                </el-form-item>
                <el-form-item label="驳回原因" v-if="detail_info_stote.status == 2">
                    <div style="color:red">{{ detail_info.remark }}</div>
                </el-form-item>
                <el-form-item label="商家电话">{{ detail_info_stote.store_phone }}</el-form-item>
                <el-form-item label="联系人姓名">{{ detail_info.company_name }}</el-form-item>
                <el-form-item label="联系电话">{{ detail_info.mobile }}</el-form-item>
                <el-form-item label="经营类目">{{ detail_info.category_name }}</el-form-item>
                <el-form-item label="开户名称">{{ detail_info.bank_name }}</el-form-item>
                <el-form-item label="银行卡账号">{{ detail_info.bank_card }}</el-form-item>
                <el-form-item label="法人身份证">
                    <div class="imageList">
                        <el-image :src="detail_info.identity_card_front"
                            :preview-src-list="[detail_info.identity_card_front, detail_info.identity_card_back]"></el-image>
                        <el-image :src="detail_info.identity_card_back"
                            :preview-src-list="[detail_info.identity_card_front, detail_info.identity_card_back]"></el-image>
                    </div>
                </el-form-item>
                <el-form-item label="店铺logo">
                    <el-image :src="detail_info.shop_logo" class="logo"></el-image>
                </el-form-item>
                <el-form-item label="营业执照照片">
                    <el-image :src="detail_info.business_license"
                        :preview-src-list="[detail_info.business_license]"></el-image>
                </el-form-item>
                <el-form-item label=" 门店照片">
                    <el-image v-for="(item, index) in detail_info.shop_picture" :key="index" :src="item"
                        :preview-src-list="detail_info.shop_picture"></el-image>
                </el-form-item>
                <!--<el-form-item label="食品预包装备案"></el-form-item>-->
            </el-form>
        </el-dialog>

        <!-- 审核弹窗 -->
        <el-dialog title="审核" :visible.sync="examine_flag" width="600px">
            <el-form ref="form" label-width="80px">
                <el-form-item label="商家信息">
                    <UserInfo :name="detail_info.store_name" :avatar="detail_info.store_logo"></UserInfo>
                </el-form-item>
                <el-form-item label="审核结果">
                    <el-radio-group v-model="is_examine_status">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="3">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="驳回原因" v-if="is_examine_status == 3">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="examine_remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="examine_flag = false">取 消</el-button>
                <el-button type="primary" @click="sure_examine_fn">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import UserInfo from '@/components/userInfo.vue';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        UserInfo,
        Paging
    },
    data () {
        return {
            getDateformat,
            formData: {
                page: 1,
                rows: 10,
                store_name: '',
                store_phone: '',
                f_name: '',
                f_mobile: '',
                r_name: '',
                r_mobile: '',
                status: 0,
                time: []
            },
            dataList: [],
            total_number: 0,
            detail_info_flag: false,
            detail_info: {},
            detail_info_stote: {},
            examine_flag: false,
            // 审核状态 默认通过
            is_examine_status: 2,
            examine_remark: ''
        }
    },
    created () {
        this.getDataList()
    },
    methods: {
        openExamineDialog (data) {
            this.is_examine_status = 2
            this.examine_remark = ''
            this.detail_info = data
            this.examine_flag = true
        },
        clearSearch () {
            this.formData = {
                page: 1,
                rows: 10,
                store_name: '',
                store_phone: '',
                f_name: '',
                f_mobile: '',
                r_name: '',
                r_mobile: '',
                status: 0,
                time: []
            }
            this.getDataList()
        },
        updateData (val, status) {
            if (status == 0) {
                this.formData.rows = val;
            } else {
                this.formData.page = val;
            }
            this.getDataList()
        },
        getDataList (style) {
            if (style) this.formData.page = 1
            let obj = {
                page: this.formData.page,
                rows: this.formData.rows,
                status: this.formData.status,
            }
            if (this.formData.store_name) obj.store_name = this.formData.store_name
            if (this.formData.store_phone) obj.store_phone = this.formData.store_phone
            if (this.formData.f_name) obj.f_name = this.formData.f_name
            if (this.formData.f_mobile) obj.f_mobile = this.formData.f_mobile
            if (this.formData.r_name) obj.r_name = this.formData.r_name
            if (this.formData.r_mobile) obj.r_mobile = this.formData.r_mobile
            if (this.formData.time?.length) {
                obj.start_time = this.formData.time[0].getTime() / 1000;
                obj.end_time = this.formData.time[1].getTime() / 1000;
                if (obj.start_time == obj.end_time) obj.end_time = obj.end_time + 86400;
            }
            this.$axios.post(this.$api.serviceProvider.MeituanSettledList, obj)
                .then(res => {
                    if (res.code === 0) {
                        this.dataList = res.result.list
                        this.total_number = res.result.total_number
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        lookDetail (data) {
            this.$axios.post(this.$api.serviceProvider.MeituanSettledInfo, {
                id: data.id
            }).then(res => {
                if (res.code === 0) {
                    res.result.shop_picture = JSON.parse(res.result.shop_picture)
                    this.detail_info = res.result
                    this.detail_info_stote = data
                    this.detail_info_flag = true
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 完成平台审核
        sure_examine_fn () {
            if (this.is_examine_status == 3 && this.examine_remark == '') {
                this.$message.error('请填写驳回原因')
                return
            }
            this.$confirm(`此操作将${this.is_examine_status == 2 ? '同意' : '驳回'}该商家的申请, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let obj = {
                    id: this.detail_info.id,
                    status: this.is_examine_status
                }
                if (this.is_examine_status == 3) obj.remark = this.examine_remark
                return this.$axios.post(this.$api.serviceProvider.MeituanSettledUpStatus, obj)
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('操作成功')
                    this.examine_flag = false
                    this.getDataList()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(() => { });
        }
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .imageList {
        display: flex;
        align-items: center;


    }

    .el-image {
        margin-right: 20px;
        width: 200px;
        height: 80px;
    }

    /deep/ .logo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
}
</style>